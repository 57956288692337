<template>
  <b-row class="match-height">
    <b-col
      lg="8"
      class="space_"
    >
      <ConfirmProfile :profile-dic="profileDic" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ConfirmProfile from '@/components/teacher/profile/ConfirmProfile.vue'
import { mapGetters } from 'vuex'
import getDataList from '@/firestore/data/getListByUserID'

export default {
  components: {
    BRow,
    BCol,
    ConfirmProfile,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('profileBasic', ['profileDic']),
    ...mapGetters('userBasic', ['userType', 'teacherID']),
  },
  async mounted() {
    const data = {
      collection: 'profile',
      userID: this.teacherID,
    }
    const response = await getDataList(data)
    if (response.status === 'success') {
      this.$store.dispatch('profileBasic/updateDic', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style scoped>
.space_ {
  margin: 0 auto;
}
</style>
